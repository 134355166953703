import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { getCartTotal } from "../../../services";
import { removeFromCart } from "../../../actions";
import { PRODUCT_DETAIL_PAGE } from "../../../constants/routes";
import { addResImagesHandler } from "../../../utils";
import { Popover } from "antd";

function CartMenu(props) {
	const { cartlist, removeFromCart, userData, capsuleNavigationCart } = props;
	let total = getCartTotal(cartlist);
	if (capsuleNavigationCart) {
		return (
			<>
				<div className="dropdown cap-nav-cart-dropdown cart-dropdown mx-0">
					<Link
						to={`${process.env.PUBLIC_URL}/shop/cart`}
						className="position-relative d-flex align-items-center h-100"
						role="button">
						<img src={`${process.env.PUBLIC_URL}/assets/images/revamp/cart-icon.svg`} />
						<span className="cart-count">{cartlist.length}</span>
					</Link>

					<div className={`dropdown-menu dropdown-menu-right rounded ${cartlist.length === 0 ? "text-center" : ""}`}>
						{0 === cartlist.length ? (
							<p className="mb-0">No products in the cart.</p>
						) : (
							<>
								<div className="dropdown-cart-products">
									{cartlist.map((item, index) => {
										const sortedImages =
											item &&
											item.images &&
											item.images.sort((a, b) => {
												if (a.orderofimage === null || a.orderofimage === undefined) return 1;
												if (b.orderofimage === null || b.orderofimage === undefined) return -1;
												return a.orderofimage - b.orderofimage;
											});
										return (
											<div
												className="product"
												key={item.id}>
												<div className="product-cart-details">
													<h4 className="product-title">
														<Link to={PRODUCT_DETAIL_PAGE(item.name, item.uuid)}>{item.name}</Link>
													</h4>

													<span className="cart-product-info">
														<span className="cart-product-qty">{item.qty}</span>x $
														{item.discount && item.salePrice < item.price
															? item.salePrice.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })
															: item.price.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
													</span>
												</div>

												<figure className="product-image-container">
													<Link
														to={PRODUCT_DETAIL_PAGE(item.name, item.uuid)}
														className="product-image">
														{sortedImages && sortedImages.length > 0 && sortedImages[0] && sortedImages[0].path ? (
															<img
																alt={item.name}
																onError={(e) => {
																	e.target.src = `${process.env.PUBLIC_URL}assets/images/products/shop/sm-1.jpg`;
																}}
																src={addResImagesHandler(sortedImages && sortedImages.length > 0 && sortedImages[0] && sortedImages[0].path, "cart")}
															/>
														) : (
															<img
																alt={item.name}
																src={`${process.env.PUBLIC_URL}assets/images/products/shop/sm-1.jpg`}
															/>
														)}
													</Link>
												</figure>
												<button
													className="btn-remove"
													title="Remove Product"
													onClick={() => removeFromCart(item, userData.isLoggedIn ? userData.data.auth.access_token : undefined)}>
													<i className="icon-close"></i>
												</button>
											</div>
										);
									})}
								</div>
								<div className="dropdown-cart-total">
									<span>Total</span>

									<span className="cart-total-price">${total.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span>
								</div>

								<div className="dropdown-cart-action">
									<Link
										to={`${process.env.PUBLIC_URL}/shop/cart`}
										className="btn btn-primary">
										View Cart
									</Link>
									<Link
										to={`${process.env.PUBLIC_URL}/shop/checkout`}
										className="btn btn-outline-primary-2">
										<span>Checkout</span>
										<i className="icon-long-arrow-right fa-xl"></i>
									</Link>
								</div>
							</>
						)}
					</div>
				</div>
			</>
		);
	}
	return (
		<div className="dropdown cart-dropdown mx-0">
			<Link
				to={`${process.env.PUBLIC_URL}/shop/cart`}
				className="dropdown-toggle"
				role="button"
				data-toggle="dropdown"
				aria-haspopup="true"
				aria-expanded="false"
				data-display="static">
				<i class="fal fa-cart-arrow-down"></i>
				<span className="cart-count">{cartlist.length}</span>
				<span className="cart-txt">Cart</span>
			</Link>

			<div className={`dropdown-menu dropdown-menu-right ${cartlist.length === 0 ? "text-center" : ""}`}>
				{0 === cartlist.length ? (
					<p className="mb-0">No products in the cart.</p>
				) : (
					<>
						<div className="dropdown-cart-products">
							{cartlist.map((item, index) => {
								const sortedImages =
									item &&
									item.images &&
									item.images.sort((a, b) => {
										if (a.orderofimage === null || a.orderofimage === undefined) return 1;
										if (b.orderofimage === null || b.orderofimage === undefined) return -1;
										return a.orderofimage - b.orderofimage;
									});
								return (
									<div
										className="product"
										key={item.id}>
										<div className="product-cart-details">
											<h4 className="product-title">
												<Link to={PRODUCT_DETAIL_PAGE(item.name, item.uuid)}>{item.name}</Link>
											</h4>

											<span className="cart-product-info">
												<span className="cart-product-qty">{item.qty}</span>x $
												{item.discount && item.salePrice < item.price
													? item.salePrice.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })
													: item.price.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
											</span>
										</div>

										<figure className="product-image-container">
											<Link
												to={PRODUCT_DETAIL_PAGE(item.name, item.uuid)}
												className="product-image">
												{sortedImages && sortedImages.length > 0 && sortedImages[0] && sortedImages[0].path ? (
													<img
														alt={item.name}
														onError={(e) => {
															e.target.src = `${process.env.PUBLIC_URL}assets/images/products/shop/sm-1.jpg`;
														}}
														src={addResImagesHandler(sortedImages && sortedImages.length > 0 && sortedImages[0] && sortedImages[0].path, "cart")}
													/>
												) : (
													<img
														alt={item.name}
														src={`${process.env.PUBLIC_URL}assets/images/products/shop/sm-1.jpg`}
													/>
												)}
											</Link>
										</figure>
										<button
											className="btn-remove"
											title="Remove Product"
											onClick={() => removeFromCart(item, userData.isLoggedIn ? userData.data.auth.access_token : undefined)}>
											<i className="icon-close"></i>
										</button>
									</div>
								);
							})}
						</div>
						<div className="dropdown-cart-total">
							<span>Total</span>

							<span className="cart-total-price">${total.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span>
						</div>

						<div className="dropdown-cart-action">
							<Link
								to={`${process.env.PUBLIC_URL}/shop/cart`}
								className="btn btn-primary">
								View Cart
							</Link>
							<Link
								to={`${process.env.PUBLIC_URL}/shop/checkout`}
								className="btn btn-outline-primary-2">
								<span>Checkout</span>
								<i className="icon-long-arrow-right fa-xl"></i>
							</Link>
						</div>
					</>
				)}
			</div>
		</div>
	);
}

function mapStateToProps(state) {
	return {
		userData: state.userData ? (state.userData.userData ? state.userData.userData : []) : [],
		cartlist: state.cartlist.cart ? state.cartlist.cart : [],
	};
}

export default connect(mapStateToProps, { removeFromCart })(CartMenu);
