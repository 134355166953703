import React from "react";
import { Form } from "antd";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom"; // Import useHistory
import GlobalSearch from "./components/GlobalSearch";

const FeatureHeroSection = (props) => {
	const [form] = Form.useForm();
	const history = useHistory(); // Initialize useHistory

	// Define buttons and their routes
	const buttons = [
		{ label: "Panels", route: "/trader/find/ca_solar-panels" },
		{ label: "Inverters", route: "/trader/find/ca_solar-inverters" },
		{ label: "Batteries", route: "/trader/find/ca_solar-batteries" },
		{ label: "See More", route: "/trader/find" },
	];

	// Handler for button clicks
	const handleButtonClick = (route) => {
		history.push(route); // Navigate to the specified route
	};

	return (
		<div className="position-relative row">
			<div className="feature-hero-section">
				<div className="align-items-center container-fluid d-flex h-100">
					<div className="col-lg-6 offset-md-3 text-center">
						<div className="hero-content-block mb-4">
							<h1 className="hero-title">Find the solar equipment you need to get the job done.</h1>
							<p className="hero-caption">Shop new and used solar panels, batteries, inverters, and more.</p>
							{/* ============== Search Block ============== */}
							<div className="mt-5 search-block">
								<div className="advance-search-block">
									<GlobalSearch
										inputSearchPrefixIcon={true}
										showAdvance="advance"
									/>
								</div>

								<ul className="d-block list-inline mt-4 text-left">
									<li className="list-inline-item">
										<strong>Browse:</strong>
									</li>
									<li className="list-inline-item">
										{buttons.map((button, index) => (
											<button
												key={index}
												type="button"
												className="btn btn-round rounded-pills"
												onClick={() => handleButtonClick(button.route)}>
												{button.label}
											</button>
										))}
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

function mapStateToProps(state) {
	return {
		userData: state.userData ? (state.userData.userData ? state.userData.userData : []) : [],
	};
}

export default connect(mapStateToProps)(FeatureHeroSection);
