import React, { Fragment, useEffect, useState, useRef, useLayoutEffect } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { connect } from "react-redux";
// Common Header Components
import MainMenu from "./partials/main-menu";
import CartMenu from "./partials/cart-menu";
import { closeModal, getAllCartItems } from "../../actions/index";
import LoginModal from "../features/modal/login-modal";
// import Modal from "react-modal";
import { showModal } from "../../actions";
import { signout } from "../../api/auth/signout";
import { LISTING_PAGE, STORE_FRONT_PAGE } from "../../constants/routes";
import { setPathParams } from "../pages/shop/services/setParams";
import MobileMainNav from "./partials/mobile-nav";
import { AutoComplete, Drawer, Input, Tooltip } from "antd";
import { getQueryParams } from "../pages/shop/services/getParams";
import { filterProducts } from "../../../src/pages/custom-order-fulfillment/main/filterProducts";
import { IMAGES_BASE_URL, RESELLER_IDs } from "../../config";
import { filterStore } from "../../api/store-front/filter-store";
import store from "../../store";
// import action src/components/home/modules/HeroSection.jsx
import FeatureHeroSection from "../../components/home/modules/HeroSection";
import { getAllCategories, getAllBrands, getDealWishList } from "../../actions";
import { addResImagesHandler } from "../../utils";
import filterSolarDeals from "../../pages/broker/api/broker/filter_solar_deals";
import { DEAL_LISTING_PAGE } from "../../pages/broker/constants/routes";
import { HiOutlineClipboardDocumentList } from "react-icons/hi2";
import { IoStorefrontOutline } from "react-icons/io5";
import { searchStore } from "../../api/store-front/search-store";
import { Modal } from "antd";
import { BuyerIcon, SellerIcon } from "../icons";
import NavigationItems from "../home/modules/navMegaMenu/NavigationComp";
import GlobalSearch from "../home/modules/components/GlobalSearch";
import AdvanceSearchFilter from "../home/modules/components/AdvanceSearchFilter";

const Header = (props) => {
	// const { wishlist, fav_list, dealList } = props;
	let history = useHistory();
	const searchDropDown = useRef();
	const { categories, userData, products, getDealWishList } = props;
	const [user, setUser] = useState(userData.data);
	const [isLoggedIn, setIsLoggedIn] = useState(userData.isLoggedIn);
	const [searchString, setSearchString] = useState("");
	const [categoryIdProductsFilter] = useState(0);
	const [sortedCategories, setSortedCategories] = useState({});
	const [showpop, setshowpop] = useState(false);
	const [modalState] = useState(0);
	const [visible, setVisible] = useState(false);
	const [storeSuggestions, setStoreSuggestions] = useState({ data: [], loading: false });
	const [solarDealSuggestions, setSolarDealSuggestions] = useState({ data: [], loading: false });
	const [productSuggestions, setProductSuggestions] = useState({ data: [], loading: false });
	const [showDropDown, setShowDropDown] = useState(false);
	const [sticky, setSticky] = useState("");
	let { pathname } = useLocation();
	let role = (userData.isLoggedIn && userData.data.role) || [];
	useEffect(() => {
		if (userData.isLoggedIn) {
			getDealWishList(userData.data.auth.access_token);
		}
	}, []);

	useEffect(() => {
		setUser(userData.data);
		setIsLoggedIn(userData.isLoggedIn);
	}, [userData]);

	//setting search string from link
	useEffect(() => {
		store.dispatch(getAllCategories());
		store.dispatch(getAllBrands());

		if (getQueryParams().searchString) {
			setSearchString(getQueryParams().searchString);
		}
	}, []);
	useEffect(() => {
		let indexedCategories = {};
		categories.forEach((category) => {
			indexedCategories = {
				...indexedCategories,
				...{ [category.id]: { ...category } },
			};
		});
		setSortedCategories(indexedCategories);
	}, []);

	useEffect(() => {
		if (userData.isLoggedIn) {
			store.dispatch(getAllCartItems(products, userData.data.auth.access_token));
		} else {
			store.dispatch(getAllCartItems(products));
		}
	}, [products, userData]);

	function CreateAccountModal(e) {
		e.preventDefault();
		history.push(`${process.env.PUBLIC_URL}/register`);
	}

	const showfrompop = () => {
		closeModal();
		setshowpop(true);
	};

	const closeform = () => {
		setshowpop(false);
	};
	const customStyles = {
		content: {
			top: "50%",
			transform: "translateY(-50%)",
		},
		overlay: {
			backgroundColor: "rgba(77,77,77,0.6)",
			zIndex: "10000",
		},
	};

	const showDrawer = () => {
		setVisible(true);
	};

	const onClose = () => {
		setVisible(false);
	};

	const isSticky = () => {
		const scrollTop = window.scrollY;
		const stickyClass = scrollTop >= 250 ? "is-sticky" : "";
		setSticky(stickyClass);
	};

	// }, [pathname]);

	const otherPagesClassHandler = () => {
		const handleBody = document.querySelector("body");

		handleBody.classList.remove("body-capsule");
	};

	const HomePageClassHandler = () => {
		// const handleStickyHeaderHome = document.querySelector('[class*="header header-14"]');
		// const handleStickyHeaderBottom = document.querySelector('[class*="header-bottom"]');

		// const handleBody = document.querySelector("body");

		// handleBody.classList.add("body-capsule");

		// if (handleStickyHeaderBottom) {
		// 	handleStickyHeaderBottom.classList.remove("sticky-header");
		// 	handleStickyHeaderBottom.classList.remove("fixed");
		// }

		// if (handleStickyHeaderHome) {
		// 	handleStickyHeaderHome.classList.add("sticky-header");
		// }
		// // he is effect when a user come back from other then landing page.
		// if (handleStickyHeaderHome || handleStickyHeaderBottom) {
		// 	handleStickyHeaderHome.classList.remove("fixed");
		// 	handleStickyHeaderBottom.classList.remove("fixed");
		// }
	};

	const location = useLocation();

	// Check if the current path is the home page ("/")
	const isHomePage = location.pathname === "/";


	useEffect(() => {
		if (pathname === "/") {
			HomePageClassHandler();
		}
		window.addEventListener("scroll", isSticky);
		return () => {
			window.removeEventListener("scroll", isSticky);
			if (pathname === "/") {
				otherPagesClassHandler();
			}
		};
	}, [pathname]);

	let mobilesearch = (
		<div className="mobile-menu-container">
			<div className="mobile-menu-wrapper">
				<button
					className="mobile-menu-close"
					onClick={onClose}>
					<i className="fal fa-times-circle"></i>
				</button>
				<div className="call-info">
					<i className="fal fa-phone-volume text-default"></i>
					<a
						href="tel:(216)800-9300"
						className="call-us">
						(216)800-9300
					</a>
					<small>Have Questions? We have answers! </small>
				</div>
			</div>
		</div>
	);

	useEffect(() => {
		document.addEventListener("mousedown", handleOutsideModalEvent, true);
	}, [showDropDown]);

	const handleOutsideModalEvent = (e) => {
		if (searchDropDown.current && !searchDropDown.current.contains(e.target)) {
			setShowDropDown(false);
		}
	};

	return (
		<>
		
			<header  className={`header header-14 sticky-header ${isHomePage ? "home-navigtion-block" : ""}`}  >
				<div className={`header-middle ${sticky}`}>
					<div className="container-fluid">
						<div className="row d-flex align-items-center">
							<div className="col-lg-3 col-6">
								<button
									className="mobile-menu-toggler"
									onClick={showDrawer}>
									<span className="sr-only">Sunhub Mobile Menu</span>
									<i className="icon-bars"></i>
								</button>

								<Drawer
									title={mobilesearch}
									placement="left"
									closable={false}
									onClose={onClose}
									visible={visible}>
									<div className="mobile-menu-container">
										<div className="mobile-menu-wrapper">
											<MobileMainNav setVisible={setVisible} />
										</div>
									</div>
								</Drawer>
								<Link
									to={`${process.env.PUBLIC_URL}/`}
									className="logo">
									<img
										src={`${process.env.PUBLIC_URL}/assets/images/header-logo.svg`}
										alt="Sunhub Logo"
										height=""
									/>
								</Link>
							</div>
							<div className="col-lg-5 hide-on-mobile hide-on-tablet">
								<div className="row align-items-center">
									<div className="col-9">
										<div className="header-search header-search-extended header-search-visible">
											<div className="header-search-wrapper search-wrapper-wide">
												<GlobalSearch
													showSearchOnMobile={false}
													showButton="button"
													inputSearchPrefixIcon={false}
												/>
											</div>
										</div>
									</div>
									<div className="col-3">
										<AdvanceSearchFilter searchButtonInsideHeader={true} />
									</div>
								</div>
							</div>
							<div className="col-6 col-lg-4 d-flex justify-content-end align-items-center">
								<div className="header-dropdown-link">
									<Link
										to="/order-status"
										className="wishlist-link order-status-link hide-on-mobile hide-on-tablet mx-0">
										<i className="fal fa-shipping-fast"></i>
										<span className="wishlist-txt"> Order Status</span>
									</Link>

									<div className="dropdown cart-dropdown hide-on-mobile mx-0">
										<div className="wishlist-link contact-link-dropdown mx-0 hide-on-mobile hide-on-tablet">
											<span>
												<i class="fal fa-phone-volume"></i>
											</span>
											<div
												className="wishlist-txt contact-txt dropdown-toggle"
												data-toggle="dropdown"
												aria-expanded="false"
												to="#contactUSDD">
												Open 24/7
											</div>
										</div>
										<div className="dropdown-menu text-center contact-dropdown">
											<div className="contact-dd-content give-us-call">
												<h6 className="dd-title">Have Questions? We have answers!</h6>
												<a
													className="call-us"
													href="tel:216-800-9300">
													(216) 800-9300
												</a>
											</div>
											<div className="dropdown-footer p-0">
												<Link
													to={`${process.env.PUBLIC_URL}/contact-us`}
													className="btn btn-white btn-block btn-lg">
													<i className="far fa-envelope"></i>Contact Us
												</Link>
											</div>
										</div>
									</div>
									{!isLoggedIn && (
										<Fragment>
											<div className="elm-to-hide">
												<div className="d-flex flex-column justify-content-center align-items-center sign-in-trigger">
													<Link
														to={`${process.env.PUBLIC_URL}/login`}
														className="text-dark"
														aria-label="Login or Register Page Links">
														<i class="fal fa-user-alt"></i>
													</Link>
													<div className="d-flex justify-content-center align-items-center signin-links">
														<Link to={`${process.env.PUBLIC_URL}/login`}>
															<span className="wishlist-txt signin-txt">Sign in</span>
														</Link>

														<small className="mx-2">/</small>
														<Link to={`${process.env.PUBLIC_URL}/register`}>
															<span className="wishlist-txt signin-txt">Join free</span>
														</Link>
													</div>
												</div>
											</div>
											<div className="elm-to-show d-none">
												<div
													className="dropdown"
													// role="dropdown"
													aria-haspopup="true">
													<div
														className="header-dropdown my-account-dropdown  dropdown-left"
														data-toggle="dropdown"
														aria-expanded="false"
														data-display="static"
														aria-haspopup="menu">
														<Link
															to="#."
															className="wishlist-link">
															<i class="fal fa-user"></i>
															{/* <span className="icon-angle-down"></span> */}
														</Link>
													</div>
													{/* // dropdown-menu-lg-left dropdown-menu-md-left dropdown-menu-sm-left */}
													<ul className="dropdown-menu dropdown-menu-left">
														<li className="dropdown-item">
															<Link to={`${process.env.PUBLIC_URL}/login`}>
																<i className="far fa-user"></i>Sign in
															</Link>
														</li>
														<li className="dropdown-item">
															<Link to={`${process.env.PUBLIC_URL}/register`}>
																<i className="far fa-user-unlock"></i>Join free
															</Link>
														</li>
													</ul>
												</div>
											</div>
										</Fragment>
									)}

									{isLoggedIn && (
										<>
											<div className="header-right">
												<div
													className="dropdown"
													// role="dropdown"
													aria-haspopup="true">
													<div
														className="header-dropdown my-account-dropdown dropdown-left"
														id="profileDropdown"
														data-toggle="dropdown"
														aria-expanded="false"
														data-display="static"
														aria-haspopup="menu">
														<Link
															to="#."
															className="wishlist-link">
															<i class="fal fa-user"></i>
															{/* <i className="fal fa-user"></i> */}
															<span className="wishlist-txt">My Profile</span>
														</Link>
													</div>
													<ul className="dropdown-menu dropdown-menu-lg-left dropdown-menu-md-left dropdown-menu-sm-left">
														<li className="menu-title">
															<small>Welcome</small>
															{user.user && user.user.firstname} {user.user && user.user.lastname}
														</li>
														<li className="dropdown-item">
															{userData && userData.data && userData.data.role && userData.data.role.includes("seller") ? (
																<Link to={`${process.env.PUBLIC_URL}/my-sunhub/seller-overview/sales-snapshot`}>
																	<span className="far fa-person-dolly-empty"></span>
																	My Store
																</Link>
															) : null}
														</li>
														<li className="dropdown-item">
															<Link to={`${process.env.PUBLIC_URL}/my-sunhub/buying-overview/purchase-history`}>
																<span class="fal fa-shopping-basket"></span>My Purchases
															</Link>
														</li>
														{userData && role && role.includes("customer") && !(userData && role.includes("seller")) && (
															<li className="dropdown-item">
																<Link to={`${process.env.PUBLIC_URL}/my-sunhub/buying-overview/purchase-history`}>
																	<span class="far fa-cash-register"></span>
																	My Trader Purchases
																</Link>
															</li>
														)}
														<li className="dropdown-item">
															{userData && userData.data && userData.data.role && userData.data.role.includes("seller") ? (
																<Link to={`${process.env.PUBLIC_URL}/trader/deals-listing`}>
																	<span class="far fa-clipboard-list"></span>
																	My Trader Listings
																</Link>
															) : null}
														</li>
														{userData && role && role.includes("customer") && (
															<li className="dropdown-item">
																<Link to={`${process.env.PUBLIC_URL}/trader/chat/buyer`}>
																	<span className="icon-comments " />
																	My Trader Bids
																</Link>
															</li>
														)}
														<li className="dropdown-item">
															{userData && userData.data && userData.data.role && userData.data.role.includes("seller") ? (
																<Link to={`${process.env.PUBLIC_URL}/trader/chat/seller`}>
																	<span className="icon-comments " />
																	My Trader Offers
																</Link>
															) : null}
														</li>

														<li className="dropdown-item">
															<Link to={`${process.env.PUBLIC_URL}/my-sunhub/user-setting`}>
																<span className="icon-cog"></span>
																My Account
															</Link>
														</li>
														{userData && userData.data && userData.data.role && userData.data.role.includes("seller") && (
															<li className="dropdown-item">
																<Link to={STORE_FRONT_PAGE(userData.data.user.id, `${user.user && user.user.firstname}`)}>
																	{/* <i className="fal fa-store-alt"></i> */}
																	<IoStorefrontOutline />
																	My Storefront
																</Link>
															</li>
														)}

														{userData && userData.data && userData.data.role && (userData.data.role.includes("seller") || userData.data.role.includes("customer")) && (
															<li className="dropdown-item">
																<Link to={`${process.env.PUBLIC_URL}/shop/wishlist`}>
																	<span className="icon-heart-o"></span>
																	Wishlist
																</Link>
															</li>
														)}

														<li className="dropdown-item">
															<Link
																to="#"
																onClick={() => signout(user.auth && user.auth.access_token, "/")}
																className="static-item">
																<span className="far fa-sign-out-alt"></span>
																Sign Out
															</Link>
														</li>
													</ul>
												</div>
											</div>
										</>
									)}

									<CartMenu />
									{isLoggedIn && (
										<div className="header-right p-0">
											{role.includes("customer") && role.includes("seller") ? (
												<div className="dropdown chat-dropdown mx-0">
													<div
														// className="header-dropdown"
														data-toggle="dropdown"
														data-display="static"
														aria-haspopup="menu">
														<Link
															className="wishlist-link"
															to="#">
															{/* <i className="fal fa-comment-alt-lines"></i> */}
															<i class="far fa-comments-alt"></i>
															<span className="wishlist-txt">Bid/Offer</span>
														</Link>
													</div>
													<ul className="dropdown-menu dropdown-menu-right dropdown-menu-lg-right dropdown-menu-md-right dropdown-menu-sm-right">
														<Tooltip
															placement="rightTop"
															title="Offers received on your deals">
															<li className="dropdown-item">
																<Link to="/trader/chat/seller">
																	<span className="icon-comments mr-2"></span>
																	Selling Offers
																</Link>
															</li>
														</Tooltip>
														<Tooltip
															placement="rightTop"
															title="Bids you made to purchase">
															<li className="dropdown-item">
																<Link to="/trader/chat/buyer">
																	<span className="icon-comments mr-2"></span>
																	<span className="wishlist-txt">My Bids</span>
																</Link>
															</li>
														</Tooltip>
													</ul>
												</div>
											) : role.includes("customer") ? (
												<Link
													to="/trader/chat/buyer"
													className="wishlist-link message-link mx-0">
													<span className="icon-comments mr-2"></span>
													<span className="wishlist-txt">My Bids</span>
												</Link>
											) : (
												role.includes("seller") && (
													<Link
														to="/trader/chat/seller"
														className="wishlist-link message-link mx-0">
														<span className="icon-comments mr-2"></span>
														<span className="wishlist-txt">Selling Offers</span>
													</Link>
												)
											)}
										</div>
									)}
								</div>
							</div>
						</div>
					</div>

					<div className="mobile-search">
						<GlobalSearch
							showButton="button"
							inputSearchPrefixIcon={false}
							showSearchOnMobile={true}
						/>
					</div>
				</div>

				<div className="header-bottom ">
					<div className="container-fluid">
						<div className="row">
							<div className="col">
								{/* <MainMenu /> */}
								<NavigationItems />
							</div>
						</div>
					</div>
				</div>
				<LoginModal modalState={modalState} />

				{/* =============== Create Account Modal =============== */}

				<Modal
					visible={showpop}
					centered
					onOk={closeform}
					onCancel={closeform}
					footer={null}
					title={
						<div className="text-center pt-3 pb-2">
							<h3>Welcome to Sunhub!</h3>
							<p>
								No worries, you can always change
								<br /> your account later if you need to.
							</p>
						</div>
					}>
					<div className="create-account-modal">
						<div className="row">
							<div className="col-md-12 text-center">
								<ul className="list-inline">
									<li className="list-inline-item">
										<div className="box-content">
											<button
												type="button"
												onClick={CreateAccountModal}
												className="box-block">
												<div className="icon-block">
													<BuyerIcon />
												</div>
											</button>
											<h4 className="title-text">Join as a Buyer</h4>
										</div>
									</li>
									<li className="list-inline-item">
										<div className="box-content">
											<Link
												onClick={closeform}
												to={`${process.env.PUBLIC_URL}/register/seller`}
												className="box-block">
												<div className="icon-block">
													<SellerIcon />
												</div>
											</Link>
											<h4 className="title-text">Join as a Seller</h4>
										</div>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</Modal>
			</header>
		</>
	);
};

function mapStateToProps(state) {
	return {
		wishlist: state.wishlist.list ? state.wishlist.list : [],
		dealList: state.wishlist.dealList ? state.wishlist.dealList : [],
		categories: state.categories.categories ? state.categories.categories : [],
		filters: state.filters,
		products: state.data.products ? state.data.products : [],
		userData: state.userData ? (state.userData.userData ? state.userData.userData : []) : [],
		fav_list: state.storeFront.fav_list ? state.storeFront.fav_list : [],
	};
}

export default connect(mapStateToProps, { showModal, getDealWishList })(Header);
