import { POST } from "../api";
import { SEARCH_PRODUCT } from "../../constants/api";
import { toast } from "react-toastify";
import { SOMETHING_WRONG } from "../../lang/en/messages";

export const filterItems = async (query = {}, callback) => {
	const data = {
		fields: [
			"id",
			"uuid",
			"owner_id",
			"dynamic_fields",
			"name",
			"description",
			"condition",
			"category_id",
			"brand_id",
			"price",
			"final_price",
			"is_taxable",
			"cost",
			"discount",
			"discount_unit",
			"age",
			"age_unit",
			"moq",
			"total_qty",
			"um",
			"msrp",
			"mpn",
			"weight",
			"weight_unit",
			"length",
			"length_unit",
			"width",
			"width_unit",
			"height",
			"height_unit",
			"package_type",
			"upc",
			"part_number",
			"sku",
			"last_sku",
			"type",
			"shipping_type",
			"package_type",
			"publish_date",
			"is_active",
			"reviews_total",
			"reviews_avg",
			"options",
			"scalling_price",
			"variants",
			"created_at",
			"meta_name",
			"meta_description",
		],
		with: ["images", "documents", "category", "brand", "owner", "locations", "bulk_savings", "owner.seller_detail"],
		where: [
			["is_active", 1],
			["status", "approved"],
		],
		limit: query.per_page,
		page: query.page_no,
	};

	if (typeof query !== "undefined" && Object.keys(query).length > 0) {
		data.where_in = [];
		//mega menu
		if (typeof query.megaMenu !== "undefined") {
			data.where.push(["is_mega_menu", "=", query.megaMenu]);
		}
		if (typeof query.owner_id !== "undefined") {
			data.where.push(["owner_id", "=", query.owner_id]);
		}
		//search string
		if (query.search && query.search !== "") {
			data.query = {
				value: "+*" + query.search.replace(/\s+/g, "* +*") + "*",
				fields: ["name", "part_number", "sku"],
			};
		}

		//category and subcategory
		if (typeof query.subCategories !== "undefined" && query.subCategories.length > 0) {
			data.where_in.push({ field: "category_id", value: query.subCategories });
		} else if (typeof query.categories !== "undefined" && query.categories.length > 0) {
			data.where_has = {
				category: {
					where: [["parent_id", query.categories[0]]],
				},
			};
		}

		//brands
		if (typeof query.brands !== "undefined" && query.brands.length > 0) {
			data.where_in.push({ field: "brand_id", value: query.brands });
		}

		//shipping Types
		if (typeof query.shippingType !== "undefined" && query.shippingType.length > 0) {
			if (query.shippingType.includes("local_pickup_only") || query.shippingType.includes("fedex")) {
				query.shippingType.push("fedex_or_local_pickup");
			}
			data.where_in.push({ field: "shipping_type", value: query.shippingType });
		}

		//condition
		if (typeof query.condition !== "undefined" && query.condition.length > 0) {
			data.where_in.push({ field: "condition", value: query.condition });
		}

		// sort by
		if (typeof query.sort_by !== "undefined" && query.sort_by === "price, high to low") {
			data.orderby = { final_price: "DESC" };
		} else if (typeof query.sort_by !== "undefined" && query.sort_by === "price, low to high") {
			data.orderby = { final_price: "ASC" };
		} else if (typeof query.sort_by !== "undefined" && query.sort_by === "alphabetically, a-z") {
			data.orderby = { name: "ASC" };
		} else if (typeof query.sort_by !== "undefined" && query.sort_by === "alphabetically, z-a") {
			data.orderby = { name: "DESC" };
		} else if (typeof query.sort_by !== "undefined" && query.sort_by === "quantity, low to high") {
			data.orderby = { total_qty: "ASC" };
		} else if (typeof query.sort_by !== "undefined" && query.sort_by === "quantity, high to low") {
			data.orderby = { total_qty: "DESC" };
		} else if (typeof query.sort_by !== "undefined" && query.sort_by === "recently added") {
			data.orderby = { id: "DESC" };
		}
	}
	POST(SEARCH_PRODUCT, data, function (data) {
		if (data.status && data.status) {
			const products = [];
			for (let i = 0; i < data.data.length; i++) {
				const product = data.data[i];
				let salePrice = product.price;
				let moq = product.moq;
				let bulkSavings = [];
				let condition = product.condition.charAt(0).toUpperCase() + product.condition.slice(1);

				if (product.scalling_price) {
					bulkSavings = product.bulk_savings;
				}

				if (product.discount && product.discount_unit === "percent") {
					salePrice = product.price - (product.price / 100) * product.discount;
				} else if (product.discount && product.discount_unit === "fixed") {
					salePrice = product.price - product.discount;
				}
				products.push({
					id: product.id,
					name: product.name,
					price: product.price,
					final_price: product.final_price,
					pictures: product.images,
					images: product.images,
					dynamic_fields: product.dynamic_fields,
					imagePlaceholder: "assets/images/products/shop/sm-1.jpg",
					shortDesc: product.description,
					stock: product.total_qty,
					category: [product.category ? product.category.name : ""],
					brands: [product.brand ? product.brand.name : ""],
					ratings: product.reviews_total,
					reviews: product.reviews_total,
					featured: true,
					age: product.age,
					age_unit: product.age_unit,
					brand_id: product.brand_id,
					category_id: product.category_id,
					main_category: (product.category && product.category.parent_id) || 0,
					condition: condition,
					cost: product.cost,
					created_at: product.created_at,
					deleted_at: product.deleted_at,
					salePrice: salePrice,
					discount_unit: product.discount_unit,
					discount: product.discount,
					height: product.height,
					height_unit: product.height_unit,
					is_active: product.is_active,
					is_taxable: product.is_taxable,
					last_sku: product.last_sku,
					length: product.length,
					length_unit: product.length_unit,
					moq: moq,
					mpn: product.mpn,
					msrp: product.msrp,
					options: product.options,
					owner_id: product.owner_id,
					package_type: product.package_type,
					part_number: product.part_number,
					publish_date: product.publish_date,
					reviews_avg: product.reviews_avg,
					short_name: product.short_name,
					sku: product.sku,
					tags: product.tags,
					type: product.type,
					um: product.um,
					upc: product.upc,
					shipping_type: product.shipping_type,
					updated_at: product.updated_at,
					uuid: product.uuid,
					variants: product.variant,
					weight: product.weight,
					weight_unit: product.weight_unit,
					width: product.width,
					scalling_price: product.scalling_price,
					width_unit: product.width_unit,
					owner: product.owner,
					documents: product.documents,
					bulk_savings: bulkSavings,
					meta_name: product.meta_name,
					meta_description: product.meta_description,
					locations: product.locations,
					is_enable: product.owner && product.owner.seller_detail && product.owner.seller_detail.is_enable,
				});
			}
			const result = {
				products: products && products.filter((product) => product.is_active !== false),
				pagination: data.pagination,
			};
			callback(result);
		} else {
			if (data.http_error) {
				toast.error(data.error);
			} else {
				if (data.errors) {
					for (let i = 0; i < data.errors.length; i++) {
						toast.error(data.errors[i]);
					}
				} else {
					toast.error(SOMETHING_WRONG);
				}
			}
		}
	});
};
