//getting filter value from parameters

import { urlToText } from "../../../../utils";

export function getPathParams(params) {
	var category = [];
	var subcategory = [];
	var condition = [];
	var shippingType = [];
	var brand = [];
	var panelWattage = [];
	var color = [];
	var intercoms = [];
	var moqUnit = [];
	var range = [];
	var company_tier = [];

	if (params) {
		let allParams = params.split("/");
		allParams.map((item) => {
			if (item.startsWith("ca_")) {
				category.push(item.replace("ca_", "").toLowerCase());
			}
			if (item.startsWith("sb_")) {
				subcategory.push(item.replace("sb_", "").replace(/-/g, " ").replace(new RegExp("!", "g"), "/"));
			}
			if (item.startsWith("br_")) {
				brand.push(item.replace("br_", "").replace(/-/g, " ").replace(new RegExp("[+]", "g"), "-"));
			}
			if (item.startsWith("cd_")) {
				condition.push(item.replace("cd_", "").replace(/-/g, " "));
			}
			if (item.startsWith("st_")) {
				shippingType.push(item.replace("st_", "").replace(/-/g, " "));
			}
			if (item.startsWith("pw_")) {
				panelWattage.push(urlToText(item.replace("pw_", "")));
			}
			if (item.startsWith("co_")) {
				color.push(urlToText(item.replace("co_", "")));
			}
			if (item.startsWith("ic_")) {
				intercoms.push(urlToText(item.replace("ic_", "")));
			}
			if (item.startsWith("mu_")) {
				moqUnit.push(urlToText(item.replace("mu_", "")));
			}

			if (item.startsWith("pr_")) {
				range.push(urlToText(item.replace("pr_", "")));
			}

			return "";
		});
	}
	return {
		category,
		subcategory,
		condition,
		shippingType,
		brand,
		panelWattage,
		color,
		intercoms,
		moqUnit,
		range,
		company_tier,
	};
}

export function getQueryParams() {
	var searchString;
	var sort_by;
	var page_no;
	var per_page;
	{
		let params = new URL(document.location).searchParams;
		searchString = params.get("q");
		sort_by = params.get("sort_by");
		page_no = params.get("page") || params.get("page_no");
		per_page = params.get("per_page");
	}
	return { searchString, sort_by, page_no, per_page };
}
