import React from "react";
import { Link, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import { IMAGES_BASE_URL } from "../../constants/api";

const Breadcrumb = (props) => {
	const { title, products, adClass, container, type = "normal", slug = "default", brokerContainer, productId, ...parent } = props;
	const location = useLocation();
	let path = [];
	let x, prevProducts, prevProduct, currentProducts, nextProducts, nextProduct;

	for (x in parent) {
		if ("function" !== typeof parent[x]) path.push(parent[x]);
	}

	currentProducts = products.filter((item) => item.id === parseInt(productId));

	// get product for prev button.
	prevProducts = products.filter((item) => item.id < parseInt(productId));
	if (!prevProducts || !prevProducts.length) prevProduct = currentProducts[0];
	else prevProduct = prevProducts[prevProducts.length - 1];

	// get product for next button.
	nextProducts = products.filter((item) => item.id > parseInt(productId));
	if (!nextProducts || nextProducts.length === 0) nextProduct = currentProducts[0];
	else nextProduct = nextProducts[0];
	return (
		<nav
			aria-label="breadcrumb"
			className={`breadcrumb-nav ${adClass}`}>
			{type === "normal" ? (
				<div className={brokerContainer || container ? "container-fluid" : "container"}>
					<ol className="breadcrumb">
						{!location.pathname.includes("trader-admin") && (
							<li className="breadcrumb-item">
								<Link to={`${process.env.PUBLIC_URL}`}>Home</Link>
							</li>
						)}
						{location.pathname.includes("trader-admin") && (
							<li className="breadcrumb-item">
								<Link to={`${process.env.PUBLIC_URL}/trader/trader-admin/listing`}>Listing</Link>
							</li>
						)}
						<li
							className="breadcrumb-item active"
							aria-current="page">
							{title}
						</li>
					</ol>
				</div>
			) : type === "product" ? (
				<div className={`${brokerContainer ? "container-fluid" : "container"}  d-flex align-items-center`}>
					<ol className="breadcrumb">
						<li className="breadcrumb-item">
							<Link to={`${process.env.PUBLIC_URL}`}>Home</Link>
						</li>
						{path.map((item, index) => (
							<li
								className="breadcrumb-item"
								key={item.id}>
								<Link to={`${process.env.PUBLIC_URL}/shop`}>{item[0]}</Link>
							</li>
						))}
						<li
							className="breadcrumb-item active"
							aria-current="page">
							{currentProducts[0] ? currentProducts[0].name : title}
						</li>
					</ol>

					<nav
						className="product-pager ml-auto"
						aria-label="Product">
						<Link
							className="product-pager-link product-pager-prev"
							to={`${process.env.PUBLIC_URL}/${type}/${slug}/${prevProduct && prevProduct.id}`}
							aria-label="Previous"
							tabIndex="-1">
							<i className="icon-angle-left"></i>
							<span>Prev</span>
							{prevProduct ? (
								<div className="product-detail">
									<figure>
										{prevProduct.pictures[0] ? (
											<img
												src={IMAGES_BASE_URL + prevProduct.pictures[0].path}
												alt="Product"
											/>
										) : (
											<img
												src={process.env.PUBLIC_URL + "/" + prevProduct.imagePlaceholder}
												alt="Product"
											/>
										)}
									</figure>
									<h3 className="product-name">{prevProduct.name}</h3>
								</div>
							) : (
								""
							)}
						</Link>

						<Link
							className="product-pager-link product-pager-next"
							to={`${process.env.PUBLIC_URL}/${type}/${slug}/${nextProduct.id}`}
							aria-label="Next"
							tabIndex="-1">
							<span>Next</span>

							<i className="icon-angle-right"></i>
							{nextProduct ? (
								<div className="product-detail">
									<figure>
										{nextProduct.pictures[0] ? (
											<img
												src={IMAGES_BASE_URL + nextProduct.pictures[0].path}
												alt="Product"
											/>
										) : (
											<img
												src={process.env.PUBLIC_URL + "/" + nextProduct.imagePlaceholder}
												alt="Product"
											/>
										)}
									</figure>

									<h3 className="product-name">{nextProduct.name}</h3>
								</div>
							) : (
								""
							)}
						</Link>
					</nav>
				</div>
			) : (
				""
			)}
		</nav>
	);
};

function mapStateToProps(state) {
	return {
		products: state.data.products ? state.data.products : [],
	};
}

export default connect(mapStateToProps)(Breadcrumb);
