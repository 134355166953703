import React, { useState, useEffect } from "react";
import { ToastContainer } from "react-toastify";
// import Custom Components
import Header from "./common/header";
import Footer from "./common/footer";
// import Utils
import { preventProductDefault, removePreventProductDefault, stickyHeaderHandler } from "../utils";
import { addAltForChatImages } from "../utils";
import { useLocation } from "react-router-dom";

function App(props) {
	const [visibility, setVisibility] = useState(false);
	const [container] = useState("container");
	const [prevPath] = useState("");
	let notHeaderFooterRoutes = [
		"/login",
		"/register",
		"/reset-password",
		"/password/reset",
		"/trader/trader-admin/listing",
		"/trader/trader-admin/listing/update",
		"/trader/trader-admin/product-detail",
		"/trader/trader-admin",
		"/trader/trader-admin/price-listing",
		"/trader/trader-admin/listing-status",
		"/trader/trader-admin/offer-bid-listing",
		"/trader/trader-admin/chat",
		"/promo/mission-solar-24MAA",
	];
	useEffect(() => {
		setTimeout(() => {
			document.querySelector("body").classList.add("loaded");
			document.querySelector("#root").classList.add("loaded");
		}, 200);
	});

	useEffect(() => {
		//for chat library image tag alt attribute
		addAltForChatImages();
		// // set sticky header
		stickyHeaderHandler();
		window.addEventListener("scroll", stickyHeaderHandler, true);
		// prevent product thumb icons
		preventProductDefault();

		return () => {
			window.removeEventListener("scroll", stickyHeaderHandler);

			// remove listeners of prevent product
			removePreventProductDefault();
		};
	}, []);

	const location = useLocation();
	useEffect(() => {
		// execute your function here every time the URL changes
		if (!notHeaderFooterRoutes.includes(window.location.pathname.toString()) && !window.location.pathname.includes("/trader/trader-admin/product-detail")) {
			setVisibility(true);
		} else {
			setVisibility(false);
		}
	}, [location.pathname]);

	return (
		<div className="page-wrapper">
			{visibility && (
				<Header
					container={container}
					urls={prevPath}
				/>
			)}

			{props.children}

			{visibility && <Footer container={container} />}

			<ToastContainer
				autoClose={3000}
				className="toast-container"
			/>
		</div>
	);
}
export default App;
